import React from "react";

const Spotify = ({ fill, width, height, ...props }) => (
  <svg
    viewBox="0 0 226.73334 226.72"
    height={height}
    width={width}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M1345.62 945.23c-269.94 160.31-715.202 175.05-972.893 96.84-41.383-12.55-85.145 10.81-97.68 52.19-12.543 41.4 10.793 85.13 52.207 97.71 295.812 89.8 787.556 72.45 1098.316-112.02 37.22-22.09 49.43-70.16 27.37-107.329-22.08-37.215-70.19-49.484-107.32-27.391zm-8.84-237.437c-18.94-30.734-59.12-40.363-89.81-21.504-225.05 138.336-568.216 178.41-834.466 97.594-34.527-10.434-70.996 9.031-81.484 43.496-10.403 34.527 9.074 70.93 43.539 81.426 304.152 92.295 682.261 47.593 940.741-111.254 30.69-18.899 40.35-59.102 21.48-89.758zm-102.47-228.031c-15.05-24.672-47.18-32.41-71.77-17.371-196.653 120.187-444.173 147.339-735.673 80.75-28.09-6.442-56.086 11.168-62.492 39.25-6.434 28.089 11.102 56.078 39.254 62.488 318.996 72.926 592.633 41.539 813.361-93.34 24.61-15.027 32.36-47.18 17.32-71.777zM850.254 1687.63c-462.496 0-837.434-374.92-837.434-837.415C12.82 387.68 387.758 12.77 850.254 12.77c462.506 0 837.426 374.91 837.426 837.445 0 462.495-374.92 837.415-837.426 837.415"
      fill={fill}
      fillOpacity={1}
      fillRule="nonzero"
      stroke="none"
      transform="matrix(1.33333 0 0 -1.33333 0 226.72) scale(.1)"
    />
  </svg>
);

Spotify.defaultProps = {
  fill: "#fff",
  height: 226.72,
  width: 226.73334,
};

export default Spotify;
