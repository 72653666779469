import React from "react";

function Zvw() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="500.532"
      height="500.504"
      version="1.1"
      viewBox="0 0 500.532 500.504"
    >
      <g fillOpacity="1" fillRule="nonzero" stroke="none">
        <path
          fill="#00adef"
          d="M9.67 290.122H500.2v490.504H9.67V290.122z"
          transform="translate(-4.67 -285.122)"
        />
        <path
          fill="#fff"
          d="M28.237 307.514H261.89L153.296 463.376H253.31v65.806H21.425l107.263-156.294H27.782l.454-65.374zm235.44 0h70.391V411.71l73.167-104.195h83.875L334.068 529.182h-70.39V307.514zm.301 233.95l70.2-.529v103.412l73.151-103.412h81.886L334.178 763.798h-70.75V661.76l-73.467 102.038h-72.616V540.935h72.616v105.568l74.017-105.039z"
          transform="translate(-4.67 -285.122)"
        />
      </g>
    </svg>
  );
}

export default Zvw;
