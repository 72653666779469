import React, { cloneElement, useContext, Children } from "react";
import { OverlayStateContext } from "utils/decorators/OverlayStateProvider";
import { Container, MenuItems } from "./style";
import HamburgerIcon from "./HamburgerIcon";
import MenuOverlay from "./MenuOverlay";

export default function HamburgerMenu({ children, ...other }) {
  const { shownOverlay, setOverlay } = useContext(OverlayStateContext);
  const isExpanded = shownOverlay === "menu";
  const setExpanded = () => {
    isExpanded ? setOverlay(null) : setOverlay("menu");
  };
  const decoratedChildren = Children.map(children, child =>
    cloneElement(child, { setExpanded })
  );

  return (
    <Container {...other}>
      <HamburgerIcon expanded={isExpanded} onClick={setExpanded} />
      <MenuOverlay isVisible={isExpanded}>
        <MenuItems>{decoratedChildren}</MenuItems>}
      </MenuOverlay>
    </Container>
  );
}
