import { css } from "styled-components";
/* 
Used for fluid typography between two points.
Uses a linear function in between these points and
a constant outside these two points
*/

function getFluidSizingCss(el, x1, y1, x2, y2) {
  const slope = (y2 - y1) / (x2 - x1);
  const b = y1 - slope * x1;
  const fluidRule = `calc(${slope * 100}vw + ${b}px)`;

  return css`
    ${el} {
      font-size: ${y1}px;
    }
    @media (min-width: ${x1}px) {
      ${el} {
        font-size: ${fluidRule};
      }
    }
    @media (min-width: ${x2}px) {
      ${el} {
        font-size: ${y2}px;
      }
    }
  `;
}

export default getFluidSizingCss;
