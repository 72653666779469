import styled from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.footer`
  background: linear-gradient(
    ${({
      theme: {
        colors: { blueishGray },
      },
    }) => `${blueishGray},${darken(0.03, blueishGray)}`}
  );
  background-color: ${_ => _.theme.colors.blueishGray};
  padding: 4.5rem ${_ => _.theme.layout.sideMarginOuterDesktop};
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const Links = styled.span`
  a {
    display: inline-block;
    @media (max-width: 540px) {
      display: block;
    }
    &:first-child {
      margin-right: 1rem;
    }
  }
`;

export const Divider = styled.i`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 ${_ => _.theme.layout.sideMarginOuterDesktop};
  height: 0.1538rem;
  width: calc(100vw - 2 * ${_ => _.theme.layout.sideMarginOuterDesktop});
  background: linear-gradient(
    90deg,
    rgba(236, 236, 236, 0) 0%,
    rgba(236, 236, 236, 0.75) 8%,
    rgba(236, 236, 236, 0.75) 92%,
    rgba(236, 236, 236, 0) 100%
  );
`;

export const CopyRightSpan = styled.span`
  flex: 1;
`;
