import React from "react";
import styled, { css } from "styled-components";

const expandedStyles = css`
  i:nth-of-type(1) {
    transform: rotate(45deg);
  }
  i:nth-of-type(2) {
    opacity: 0;
  }
  i:nth-of-type(3) {
    transform: translateY(0.38rem) rotate(-45deg);
  }
`;

const Bars = styled.button`
  outline: none;
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  opacity: 0.75;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }

  ${_ => _.expanded && expandedStyles}

  @media (max-width: 540px) {
    display: block;
  }
`;

const Bar = styled.i`
  display: block;
  margin: 0.4rem 0;
  width: 1.941rem;
  height: 0.125rem;
  background-color: ${_ => _.theme.colors.textPrimary};
  transition: all 0.2s ease-out;

  &:first-of-type {
    margin-top: 0;
    transform-origin: 0 0;
  }
  &:last-of-type {
    margin-bottom: 0;
    transform-origin: 0 0;
  }
`;

export default function HamburgerIcon(props) {
  return (
    <Bars {...props}>
      <Bar />
      <Bar />
      <Bar />
    </Bars>
  );
}
