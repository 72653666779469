import { useRef, useEffect } from "react";

// Using initially set state values directly would cause state updates on scrollAreas getting lost.
// As all registrations arrive almost at the same time, the register function (setState-function)
// would be called before the previous register function call would have been fully propagated.
// The solution is to buffer the new values in a queue (a ref array) and process them one after another
const useQueuedRegistering = (stateObject, setStateObject) => {
  const initialRegistrationsBuffer = useRef([]);
  const registerNewElement = newEntity => {
    if (initialRegistrationsBuffer.current.length === 0) {
      initialRegistrationsBuffer.current.push(newEntity);
      // call Update function without new value to start queue
      setStateObject({});
    } else {
      initialRegistrationsBuffer.current.push(newEntity);
    }
  };
  // Unqueue a new registration on every update on stateObject
  useEffect(() => {
    if (initialRegistrationsBuffer.current.length > 0) {
      const { id, ...addedEntity } = initialRegistrationsBuffer.current[0];
      setStateObject({ ...stateObject, [id]: addedEntity });
      initialRegistrationsBuffer.current.shift();
    }
  }, [stateObject, setStateObject]);

  return registerNewElement;
};

export default useQueuedRegistering;
