const animationProps = {
  duration: 600,
  smooth: "easeOutQuad",
  spy: true,
};

export const indexRoutes = {
  landing: { index: 0, name: "landing", animationProps, linkName: "Start" },
  about: { index: 1, name: "about", animationProps, linkName: "About Us" },
  music: { index: 2, name: "music", animationProps, linkName: "Music" },
  social: {
    index: 3,
    name: "social",
    animationProps,
    linkName: "Social Media",
  },
  contact: { index: 4, name: "contact", animationProps, linkName: "Contact" },
};
