import React from "react";

const Facebook = ({ fill, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 116 109" {...props}>
    <defs>
      <path id="a" d="M0 0L116 0 116 109 0 109z"></path>
    </defs>
    <g
      fill={fill}
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      transform="translate(0 -4) translate(0 4)"
    >
      <mask id="b" fill={fill}>
        <use xlinkHref="#a"></use>
      </mask>
      <path
        fill={fill}
        d="M116 54.833C116 24.55 90.033 0 58 0S0 24.55 0 54.833C0 82.202 21.21 104.886 48.938 109V70.683H34.21v-15.85h14.727v-12.08c0-13.743 8.658-21.334 21.907-21.334 6.346 0 12.983 1.071 12.983 1.071v13.494h-7.314c-7.205 0-9.451 4.227-9.451 8.563v10.286h16.085l-2.571 15.85H67.063V109C94.79 104.886 116 82.202 116 54.833"
        mask="url(#b)"
      ></path>
    </g>
  </svg>
);

Facebook.defaultProps = {
  width: 202.5,
  height: 202.5,
};

export default Facebook;
