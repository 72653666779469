import React, { createContext, useState } from "react";

export const OverlayStateContext = createContext();

export default function OverlayStateProvider({ children }) {
  const [shownOverlay, setShownOverlay] = useState(null);

  const setOverlay = overlay => {
    if (overlay === null) {
      document.body.classList.remove("prevent-scroll");
    } else {
      document.body.classList.add("prevent-scroll");
    }
    setShownOverlay(overlay);
  };

  return (
    <OverlayStateContext.Provider value={{ shownOverlay, setOverlay }}>
      {children}
    </OverlayStateContext.Provider>
  );
}
