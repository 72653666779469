import React from "react";

function ArrowBack({ width, height, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10 18"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        d="M18.436 5.556l.008.008a.803.803 0 010 1.127l-7.222 7.327 7.191 7.297a.8.8 0 010 1.123.777.777 0 01-1.099.008l-.008-.008-7.192-7.296-.003.003L9 14.018l.003-.004L9 14.012l1.107-1.124.004.003 7.222-7.327a.78.78 0 011.103-.008z"
        transform="translate(-9 -5) matrix(1 0 0 -1 0 27.67)"
      ></path>
    </svg>
  );
}

ArrowBack.defaultProps = {
  width: 10,
  height: 18,
  fill: "#fff",
};

export default ArrowBack;
