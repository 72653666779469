import { createGlobalStyle } from "styled-components";
import { transparentize } from "polished";
import getFluidSizingCss from "utils/polyFluidSizing";
import "typeface-open-sans";

const accentGreen = "#19D5B1";
const accentBlue = "#00E2FE";
const theme = {
  colors: {
    accentGreen,
    accentBlue,
    textPrimary: "#fff",
    textSecondary: "rgba(255,255,255,0.75)",
    textDark: "#454852",
    textDarkSecondary: "rgba(69, 72, 82, 0.5)",
    blueishGray: "rgb(20, 21, 26)",
    black: "#0C0D12",
  },
  gradients: {
    greenRadial: `radial-gradient(circle at 25% 50%, ${accentGreen} 0%, ${transparentize(
      1,
      accentGreen
    )} 100%)`,
    blueRadial: `radial-gradient(circle at 27.14% 36.73%, ${accentBlue} 0%, ${transparentize(
      0.7,
      "#0000FE"
    )} 33%, ${transparentize(1, "#0000FE")} 41%)`,
    retroOverlay: `radial-gradient(circle at 12% 90%, rgba(115,42,162,0.38) 0%, rgba(198,120,17,0.21) 55%, rgba(116,0,0,0.30) 100%)`,
    retroOverlayInverted: `radial-gradient(circle at 88% 90%, rgba(116,0,0,0.30) 0%, rgba(198,120,17,0.21) 45%, rgba(115,42,162,0.38) 100%)`,
  },
  layout: {
    sideMarginInnerDesktop: "18vw",
    sideMarginOuterDesktop: "7.2vw",
    sideMarginInnerMobile: "12vw",
    sideMarginOuterMobile: "4.8vw",
    paddingHeader: "1.722rem 2.388rem",
  },
};

export const GlobalStyle = createGlobalStyle`
html {
  position: relative;
  width: 100vw;
}
body {
  margin: 0;
  width: 100%;
  overflow-x:hidden;
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  background-color: #000;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;

  &.prevent-scroll {
    height: 100%;
    overflow: hidden;
  }
}

/* Font sizes:
Use viewport sized typography with a top and bottom limit.

This technique has a downside: user preferences for the text get ignored.
A more complicated technique for responsive typography is using css locks with user Preferences.
It makes use of the CSS calc() function to describe the linear growing
font size between two breakpoints. 
https://fvsch.com/css-locks
*/

${getFluidSizingCss("h2", 576, 47, 1200, 82)}
${getFluidSizingCss("h3", 576, 35, 1200, 60)}
${getFluidSizingCss("h4", 576, 20, 1200, 35)}
${getFluidSizingCss("h6", 576, 20, 1200, 35)}
${getFluidSizingCss("p", 576, 13, 1200, 16)}
${getFluidSizingCss("li", 576, 13, 1200, 16)}
${getFluidSizingCss("a", 576, 13, 1200, 16)}
${getFluidSizingCss("button", 576, 13, 1200, 16)}

h2 {
  font-weight: 900;
  text-transform: uppercase;
  color: ${props => props.theme.colors.textPrimary};
  line-height: 1.1097em;
  margin: 0 0 1rem 0;
}

h3 {
  color: ${props => props.theme.colors.textPrimary};
  margin: 3rem 0;
}

h4 {
  color: ${props => props.theme.colors.textSecondary};
  margin: 2rem 0;
}

p, li {
  color: ${props => props.theme.colors.textSecondary};
  word-break: break-word;
}

a {
  color: ${props => props.theme.colors.accentGreen};
  &:visited {
    color: ${props => props.theme.colors.accentGreen};
    text-decoration: none;
  }
}

h6 {
  color: ${props => props.theme.colors.textSecondary};
  font-weight: 400;
  margin: 0;
}

`;

export default theme;
