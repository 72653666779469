import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useTrail, animated } from "react-spring";
import IconLink from "components/molecules/IconLink";
import HamburgerMenu, {
  MenuItem,
} from "components/organisms/navigation/hamburgerNavigation";
import Icon from "components/atoms/Icon";
import { Wrapper, IconWrapper, BackLinkWrapper } from "./style";
import { indexRoutes as routes } from "pageRoutes/routes";
import PageRouteContext from "pageRoutes/PageRouteContext";
import Headroom from "react-headroom";
import { OverlayStateContext } from "utils/decorators/OverlayStateProvider";

const AnimatedMenuItem = animated(MenuItem);

export default function Header({ path }) {
  const routesArray = Object.keys(routes);
  const { activeRoute, setActiveRoute } = useContext(PageRouteContext);
  const { shownOverlay } = useContext(OverlayStateContext);
  const isInMenu = shownOverlay === "menu";
  const handleMenuItemClick = routeId => {
    setActiveRoute(routeId);
  };
  const inMainPage = path === "/" || path === "/index";
  const trailStyles = useTrail(routesArray.length, {
    from: { opacity: 0, transform: "translateY(1rem)" },
    to: {
      opacity: isInMenu ? 0.7 : 0,
      transform: isInMenu ? "translateY(0rem)" : "translateY(1rem)",
    },
    delay: 20,
  });
  return (
    <Headroom style={{ zIndex: 3 }} wrapperStyle={{ marginBottom: "-5.25rem" }}>
      <Wrapper withBackButton={!inMainPage}>
        {inMainPage ? (
          <HamburgerMenu>
            {routesArray.map((route, index) => {
              const routeId = routes[route].name;
              return (
                <AnimatedMenuItem
                  key={routeId}
                  to={routeId}
                  active={routeId === activeRoute}
                  onSetActive={() => handleMenuItemClick(routeId)}
                  style={trailStyles[index]}
                  {...routes[route].animationProps}
                >
                  {routes[route].linkName}
                </AnimatedMenuItem>
              );
            })}
          </HamburgerMenu>
        ) : (
          <BackLinkWrapper to="/">
            <Icon name="arrowBack" /> Back
          </BackLinkWrapper>
        )}
        <IconWrapper>
          <IconLink
            name="facebook"
            href="https://facebook.com/cliffhousemusic"
          />
          <IconLink
            name="instagram"
            href="https://www.instagram.com/cliffhousemusic"
          />
          <IconLink
            name="spotify"
            href="https://open.spotify.com/artist/3niuBt6jngUGu3z7P70WlK"
          />
        </IconWrapper>
      </Wrapper>
    </Headroom>
  );
}

Header.propTypes = {
  path: PropTypes.string,
};
