import React from "react";
import styled from "styled-components";
import { ScrollLink } from "react-scroll";

const Item = styled.li`
  height: 3rem;
  width: 100%;
  opacity: 0.7;
  color: ${_ => _.theme.colors.textPrimary};
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0 -2.388rem;
  padding: 0 2.388rem;
  transition: opacity 0.2s ease-out;
  cursor: pointer;
  font-size: 1.56rem !important;

  &:hover {
    opacity: 1 !important;
  }
`;

function MenuItem({ children, onClick, setExpanded, ...other }) {
  const handleItemClick = e => {
    setExpanded(false);
    onClick(e);
  };
  return (
    <Item onClick={handleItemClick} {...other}>
      {children}
    </Item>
  );
}

export default ScrollLink(MenuItem);
