import React from "react";

function Arrow({ width, height, stroke, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 110 62"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <path
          strokeWidth="4"
          d="M96 38a30.756 30.756 0 0111 9.022A23.82 23.82 0 00101.534 58"
          transform="translate(-631 -3490) translate(0 2873) translate(632 619)"
        />
        <path
          strokeWidth="3"
          d="M103.984 47.316c-1.096.684 4.565.684-32.797.684C-1.273 40.615.992 0 .992 0"
          transform="translate(-631 -3490) translate(0 2873) translate(632 619)"
        />
      </g>
    </svg>
  );
}

Arrow.defaultProps = {
  width: 110,
  height: 62,
  stroke: "#fff",
};

export default Arrow;
