import React, { useEffect, useRef, useState } from "react";
import { OverlayContainer } from "./style";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";

export default function MenuOverlay({ isVisible, children }) {
  let domAncor = useRef();
  const [isAncorMounted, setIsAncorMounted] = useState(false);
  useEffect(() => {
    domAncor.current = document.getElementById("menu-portal");
    setIsAncorMounted(true);
  }, []);
  useEffect(() => {
    if (
      !isAncorMounted &&
      domAncor.current &&
      domAncor.current instanceof Element
    ) {
      setIsAncorMounted(true);
    } else if (
      isAncorMounted &&
      (!domAncor.current || !(domAncor.current instanceof Element))
    ) {
      setIsAncorMounted(false);
    }
  }, [domAncor, setIsAncorMounted, isAncorMounted]);
  return isVisible && isAncorMounted
    ? createPortal(
        <OverlayContainer isVisible={isVisible}>{children}</OverlayContainer>,
        document.getElementById("menu-portal")
      )
    : null;
}

MenuOverlay.propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
