import React from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { desaturate } from "polished";

const outlineStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.12em solid ${props => props.theme.colors.accentGreen};
  text-transform: uppercase;
  font-weight: 600;
  padding: 1em 0;
  width: ${({ width }) => width};
  max-width: 30rem;
  color: ${props => props.theme.colors.accentGreen};
  border-radius: 0.388em;
  background-color: transparent;
  transition: all 0.2s ease-out;
  cursor: pointer;

  &:hover:not(:disabled) {
    background-color: ${props => props.theme.colors.accentGreen};
    color: ${props => props.theme.colors.textPrimary};
    box-shadow: rgba(0, 2, 5, 0.2) 0px 0.22rem 0.34rem 0,
      rgba(1, 2, 5, 0.1) 0px 0.0625rem 0.17rem 0;
    transform: translateY(-0.125rem);
  }

  &:disabled {
    cursor: not-allowed;
    color: ${props => desaturate(0.6, props.theme.colors.accentGreen)};
    border-color: ${props => desaturate(0.6, props.theme.colors.accentGreen)};
  }
`;

const iconStyles = css`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: none;
  padding: 0;
  border: none;
  outline: none;
  height: 1.5rem;
  width: 1.5rem;
  opacity: 0.75;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }

  svg {
    height: 100%;
    width: 100%;
    fill: #fff;
  }

  :not(:last-child) {
    margin: 0 1rem;
  }
  :last-child:not(:only-child) {
    margin: 0 0 0 1rem;
  }
`;

const textStyles = css`
  cursor: pointer;
  display: inline-block;
  padding: 0 0 0.3em 0;
  border: none;
  background: none;
  color: ${props => props.theme.colors.textSecondary};
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  position: relative;
`;

export const OutlineButton = styled.button`
  ${outlineStyles}
`;

const TextButtonInner = styled.button`
  ${textStyles}
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0.125em;
    background-color: ${_ => _.theme.colors.accentGreen};
    transition: width 0.3s ease-out;
  }
  &:hover:after {
    width: 100%;
  }
`;

export const TextButton = ({ to, children, ...props }) =>
  to ? (
    <Link to={to}>
      <TextButtonInner {...props}>{children}</TextButtonInner>
    </Link>
  ) : (
    <TextButtonInner {...props}>{children}</TextButtonInner>
  );

export const IconButton = styled.button`
  ${iconStyles}
`;
