import React from "react";

const Instagram = ({ fill, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 115 113" {...props}>
    <defs>
      <path
        id="a"
        d="M0 0.035631909L114.96372 0.035631909 114.96372 112.932983 0 112.932983z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        fill={fill}
        d="M57.482.036c-15.611 0-17.569.065-23.7.34C27.664.648 23.485 1.603 19.83 3 16.05 4.442 12.843 6.373 9.648 9.51c-3.196 3.139-5.161 6.287-6.63 10C1.597 23.1.625 27.206.346 33.215.066 39.236 0 41.159 0 56.49s.066 17.255.346 23.277c.279 6.009 1.25 10.112 2.672 13.703 1.469 3.713 3.434 6.861 6.63 10 3.195 3.139 6.401 5.069 10.181 6.512 3.656 1.395 7.835 2.35 13.953 2.624 6.131.274 8.089.34 23.7.34 15.611 0 17.569-.066 23.7-.34 6.118-.274 10.296-1.229 13.953-2.624 3.78-1.443 6.985-3.373 10.181-6.512 3.196-3.139 5.161-6.287 6.63-10 1.421-3.59 2.393-7.694 2.672-13.703.28-6.022.346-7.945.346-23.277s-.066-17.255-.346-23.276c-.28-6.01-1.251-10.113-2.672-13.704-1.469-3.713-3.434-6.861-6.63-10C102.12 6.373 98.915 4.442 95.135 3 91.478 1.604 87.3.65 81.182.375c-6.131-.274-8.089-.34-23.7-.34zm0 10.172c15.348 0 17.166.057 23.227.329 5.605.251 8.649 1.17 10.674 1.944 2.683 1.024 4.598 2.247 6.61 4.223 2.01 1.975 3.257 3.856 4.3 6.491.786 1.99 1.723 4.979 1.978 10.483.277 5.953.336 7.739.336 22.813 0 15.074-.059 16.86-.336 22.813-.255 5.504-1.192 8.493-1.979 10.483-1.042 2.635-2.288 4.516-4.3 6.491-2.011 1.976-3.926 3.2-6.609 4.223-2.025.773-5.07 1.693-10.674 1.944-6.06.272-7.878.33-23.227.33-15.35 0-17.167-.058-23.228-.33-5.604-.25-8.648-1.17-10.673-1.944-2.683-1.024-4.598-2.247-6.61-4.223-2.011-1.975-3.257-3.856-4.3-6.491-.787-1.99-1.723-4.979-1.979-10.483-.276-5.953-.335-7.739-.335-22.813 0-15.074.059-16.86.335-22.813.256-5.504 1.192-8.493 1.98-10.483 1.042-2.635 2.288-4.516 4.3-6.491 2.01-1.976 3.926-3.2 6.609-4.223 2.025-.773 5.069-1.693 10.673-1.944 6.061-.272 7.88-.33 23.228-.33z"
      ></path>
      <path
        fill={fill}
        d="M57.5 77.298c-10.934 0-19.798-8.864-19.798-19.798 0-10.934 8.864-19.798 19.798-19.798 10.934 0 19.798 8.864 19.798 19.798 0 10.934-8.864 19.798-19.798 19.798zM57.5 27C40.655 27 27 40.655 27 57.5S40.655 88 57.5 88 88 74.345 88 57.5 74.345 27 57.5 27zM95 27a7 7 0 11-14 0 7 7 0 0114 0"
      ></path>
    </g>
  </svg>
);

Instagram.defaultProps = {
  width: 202.5,
  height: 202.5,
  fill: "#fff",
};

export default Instagram;
