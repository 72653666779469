import React, { useState } from "react";
import PageRouteContext from "pageRoutes/PageRouteContext";
import { indexRoutes as routes } from "pageRoutes/routes";

export default function PageRouteProvider({ children }) {
  const [activeRoute, setActiveRoute] = useState(routes.landing.name);
  let initialScrollPositions = {};
  Object.keys(routes).forEach(key => {
    initialScrollPositions[key] = 0;
  });

  return (
    <PageRouteContext.Provider
      value={{
        activeRoute,
        setActiveRoute,
      }}
    >
      {children}
    </PageRouteContext.Provider>
  );
}
