import React, { createContext, useState } from "react";

export const LoadingContext = createContext();

const checkEverythingLoaded = loadingImages => {
  let allLoaded = true;
  const allImgs = document.getElementsByClassName("gatsby-image-wrapper");
  const allImgIds = Object.keys(loadingImages);
  if (allImgs && allImgs.length && allImgs.length === allImgIds.length) {
    allImgIds.forEach(loadingImage => {
      if (loadingImages[loadingImage]) {
        allLoaded = false;
      }
    });
  } else {
    allLoaded = false;
  }
  return allLoaded;
};

export default function LoadingProvider({ children }) {
  const [loadingImages, setLoadingImages] = useState({});
  const [isEverythingLoaded, setIsEverythingLoaded] = useState(false);
  const registerNewImage = id =>
    setLoadingImages(prev => ({ ...prev, [id]: true }));
  const setImageLoaded = id => {
    setLoadingImages(prev => ({ ...prev, [id]: false }));
    setIsEverythingLoaded(
      checkEverythingLoaded({ ...loadingImages, [id]: false })
    );
  };
  return (
    <LoadingContext.Provider
      value={{ isEverythingLoaded, registerNewImage, setImageLoaded }}
    >
      {children}
    </LoadingContext.Provider>
  );
}
