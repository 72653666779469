import React from "react";
import Button from "components/atoms/Button";
import { Wrapper, Divider, Links, CopyRightSpan } from "./style";

export default function Footer() {
  return (
    <Wrapper>
      <Divider />
      <CopyRightSpan>© 2019 Cliff House Music</CopyRightSpan>
      <Links>
        <Button variant="text" to="/privacy">
          Privacy Policy
        </Button>
        <Button variant="text" to="/imprint">
          Imprint
        </Button>
      </Links>
    </Wrapper>
  );
}
