import styled from "styled-components";
import { Link } from "gatsby";

export const Wrapper = styled.header`
  z-index: 4;
  display: flex;
  justify-content: ${({ withBackButton }) =>
    withBackButton ? "space-between" : "flex-end"};
  align-items: flex-start;
  padding: ${_ => _.theme.layout.paddingHeader};
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;

  @media (max-width: 540px) {
    justify-content: space-between;
  }
`;

export const IconWrapper = styled.div`
  height: 1.5rem;
`;

export const BackLinkWrapper = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${_ => _.theme.colors.textPrimary} !important;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1em;
  font-weight: 600;
  opacity: 0.75;
  transition: opacity 0.2s ease-out;
  height: 1.5rem;

  svg {
    margin-right: 0.6em;
    height: 1em;

    path {
      transition: fill 0.2s ease-out;
    }
  }

  &:hover {
    opacity: 1;

    svg path {
      fill: ${_ => _.theme.colors.accentGreen};
    }
  }
`;
