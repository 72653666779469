import React from "react";
import Icon from "components/atoms/Icon";
import Button from "components/atoms/Button";

export default function IconLink({ href, name, ...props }) {
  return (
    <Button variant="icon" {...props}>
      <a href={href}>
        <Icon name={name} />
      </a>
    </Button>
  );
}
