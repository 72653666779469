import React, { createContext, useState } from "react";
import useQueuedRegistering from "utils/hooks/useQueuedRegistering";

export const ScrollContext = createContext();

export default function ScrollProvider({ children }) {
  // scroll areas just keep track of which area is in view.
  // scroll area format: <scrollAreaId>: {lower: <TriggerInPixelFromTop>, upper:<TriggerInPixelFromTop>, active: false}
  const [scrollAreas, setScrollAreas] = useState({});
  // styled scroll elements apply a style config between two points to a dom ref
  // scroll element format:
  // <scrollElementId>: {
  //    lower: <TriggerInPixelFromTop>,
  //    upper:<TriggerInPixelFromTop>,
  //    styleProp: <z.B. opacity>,
  //    from: <topValue>
  //    to: <bottomValue>
  //    domRef: <domRef>
  // }
  const [scrollElements, setScrollElements] = useState({});
  // Using setScrollAreas and scrollElements directly would cause state updates on scrollAreas getting lost.
  // As all registrations arrive almost at the same time, registerNewScrollArea/ registerNewScrollElement
  // would be called before the previous setScrollAreas/ setScrollElements call would have been fully propagated.
  // The solution is to buffer the new values in a queue (a ref array) and process them one after another
  const registerNewScrollArea = useQueuedRegistering(
    scrollAreas,
    setScrollAreas
  );
  const registerNewScrollElement = useQueuedRegistering(
    scrollElements,
    setScrollElements
  );
  const setActiveOnScrollArea = (name, isActive) => {
    const scrollAreaToBeEdited = scrollAreas[name];
    const updatedScrollArea = { ...scrollAreaToBeEdited, active: isActive };
    setScrollAreas({ ...scrollAreas, [name]: updatedScrollArea });
  };
  return (
    <ScrollContext.Provider
      value={{
        scrollAreas,
        scrollElements,
        registerNewScrollArea,
        setActiveOnScrollArea,
        registerNewScrollElement,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
}
