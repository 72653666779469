import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import theme, { GlobalStyle } from "../theme";
import PageRouteProvider from "utils/decorators/PageRouteProvider";
import ScrollProvider from "utils/decorators/ScrollProvider";
import LoadingProvider from "utils/decorators/LoadingProvider";
import OverlayStateProvider from "utils/decorators/OverlayStateProvider";
import PageTemplate from "components/templates/PageTemplate";
import Footer from "components/organisms/Footer";
import Header from "components/organisms/Header";
import BannerLogo from "images/BannerSide.png";

const Layout = ({ children, path }) => {
  return (
    <ThemeProvider theme={theme}>
      <PageRouteProvider>
        <ScrollProvider>
          <LoadingProvider>
            <OverlayStateProvider>
              <>
                <GlobalStyle />
                <PageTemplate
                  header={<Header path={path} />}
                  footer={<Footer />}
                  logoPath={BannerLogo}
                >
                  {children}
                </PageTemplate>
              </>
            </OverlayStateProvider>
          </LoadingProvider>
        </ScrollProvider>
      </PageRouteProvider>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};

export default Layout;
