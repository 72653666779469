import styled, { css } from "styled-components";

export const Container = styled.nav`
  transition: background 0.4s ease-out;
  z-index: 10;
  box-sizing: border-box;

  @media (min-width: 541px) {
    display: none;
  }
`;

export const MenuItems = styled.ul`
  padding: 0;
  margin: 5rem 2.388rem;
  font-size: 1.56rem;
`;

const visibleStyles = css`
  opacity: 1;
`;

export const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  background: linear-gradient(111deg, rgb(0, 0, 0) 0%, rgba(20, 21, 26, 0.9));
  transition: opacity 0.4s ease-out;
  backdrop-filter: contrast(150%) grayscale(85%);
  pointer-events: all;

  ${_ => _.isVisible && visibleStyles};
`;

export const MenuOverlayPortal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 1;
  ${_ => !_.isVisible && `display: none;`}
`;
