import React from "react";
import PropTypes from "prop-types";
import { OutlineButton, TextButton, IconButton } from "./style";

const Button = ({ variant, ...props }) => {
  if (variant === "outline") {
    return <OutlineButton {...props} />;
  } else if (variant === "icon") {
    return <IconButton {...props} />;
  }
  return <TextButton {...props} />;
};

Button.propTypes = {
  width: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  to: PropTypes.string,
  variant: PropTypes.oneOf(["outline", "text", "icon"]),
};

Button.defaultProps = {
  type: "button",
  width: "100%",
};

export default Button;
