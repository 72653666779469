import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
`;

const SideLogo = styled.img`
  position: fixed;
  width: 3rem;
  z-index: 2;
  @media (min-width: 540.000001px) {
    top: 4.722rem;
    left: 0;
  }
  @media (max-width: 540px) {
    right: 0;
    bottom: 4.722rem;
  }
`;

const PageTemplate = ({ header, children, footer, logoPath, ...props }) => {
  return (
    <Wrapper {...props}>
      {header}
      <SideLogo src={logoPath} />
      <main>{children}</main>
      {footer}
    </Wrapper>
  );
};

PageTemplate.propTypes = {
  header: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  logoPath: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default PageTemplate;
