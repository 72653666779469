import React from "react";
import Facebook from "./Facebook";
import Instagram from "./Instagram";
import Spotify from "./Spotify";
import AmazonMusic from "./AmazonMusic";
import Arrow from "./Arrow";
import Zvw from "./Zvw";
import CliffHouse from "./CliffHouse";
import ArrowBack from "./ArrowBack";

const Icon = props => {
  switch (props.name) {
    case "facebook":
      return <Facebook {...props} />;
    case "instagram":
      return <Instagram {...props} />;
    case "spotify":
      return <Spotify {...props} />;
    case "amazonMusic":
      return <AmazonMusic {...props} />;
    case "arrow":
      return <Arrow {...props} />;
    case "zvw":
      return <Zvw {...props} />;
    case "cliffhouse":
      return <CliffHouse {...props} />;
    case "arrowBack":
      return <ArrowBack {...props} />;
    default:
      return <div />;
  }
};
export default Icon;
